import React from 'react'
import './hero.scss'
import { BsArrowRightShort } from 'react-icons/bs'
import Slider from '../Slider/Slider'
import { Link } from 'react-router-dom'
const Hero = () => {
  return (
    <div className="row z-index-1" style={{ marginTop: "7rem" }}>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <Link className='h6 text-decoration-none' to={"/register"} style={{ fontWeight: "700", fontSize: "1rem" }}>Join Us</Link>
        <h1 className='heading'>Discover Winning Products To Sell with DSLib</h1>
        <h6 className='heading_description'>Find winning dropshipping products without wasting your time</h6>
        <div className='button_area'>
          <Link className='hero_button' type="submit" to="/register" >GET STARTED <BsArrowRightShort className='arrow_sign' /></Link>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <div className='image_area'>
          <Slider />
        </div>
      </div>
    </div>
  )
}

export default Hero