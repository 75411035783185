import "./login.scss"
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export const LoginForm = () => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState(null);
    const { email, password } = inputValue;
    const [loading, setLoading] = useState(false);
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/login`,
                {
                    ...inputValue,
                },
            );
            const { success, message, token } = data;
            localStorage.setItem('token', token);
            if (success) {
                    navigate("/dashboard");
                setInputValue({
                    ...inputValue,
                    email: "",
                    password: "",
                });

            } else {
                setError(message);
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                setError(error.response.data.error || 'An error occurred');
            } else if (error.request) {
                setError('No response received from the server');
            } else {
                setError('An error occurred while processing the request');
            }
        }
    };


    return (
        <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center register_container">
            <form onSubmit={handleSubmit} className="container rounded" style={{ backgroundColor: "#fff", height: "430px", width: "400px" }}>
                <h5 className="text-center mb-3 pt-3" style={{ fontWeight: "700" }}>LOG IN</h5>
                <div className="mb-3 row">
                    <div className="col-12">
                        <label htmlFor="staticEmail" className=" col-form-label label_text">*Email ID</label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-12">
                        <input type="text" className="form-control" id="staticEmail" name="email" value={email} onChange={handleOnChange} required placeholder="Enter Email" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-12">
                        <label htmlFor="inputPassword" className="col-form-label label_text">*Password</label>
                    </div>
                </div>
                <div>
                    <div className="col-12">
                        <input type="password" className="form-control" id="inputPassword" name="password" value={password} onChange={handleOnChange} required placeholder="Enter Password" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <div className="col-12">
                        <div className='login_button_area'>
                            <button
                                className={`login_button ${loading ? 'disabled' : ''}`}
                                disabled={loading}
                                type="submit"
                            >
                                {loading ? (
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    'LOGIN'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <span>
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </span>
                <span >
                    If you don't have an account? <Link to={"/register"}>Signup</Link>
                </span>
            </form>
        </div>

    )
}