import React from 'react'
import './help.scss'
const data = [
    {
        'id': 1,
        "text": "15K",
        "title": "Happy Customers"

    },
    {
        'id': 2,
        "text": "150K",
        "title": "Monthly Visitors"

    },
    {
        'id': 3,
        "text": "10K",
        "title": "New trending products each day"

    },

]
export const Help = () => {
    
    return (
        <div className="row" style={{ marginTop: "5rem" }}>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <h2 className='help_heading'>How we’ve helped dropshipping businesses</h2>
            </div>
            <div className='row d-flex justify-content-start' style={{ marginBottom: "5rem", marginTop: "0rem" }}>
                {
                data.map((e) => (
                    <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center text-lg-center text-xl-center' key={e.id}>
                        <h1 className='heading' style={{ color: "#ed106f" }}>{e.text}</h1>
                        <h5 className='title'>{e.title}</h5>
                    </div>
                ))

                }

            </div>

        </div>
    )
}
