import React from 'react';

const FAQ = () => {
  const faqData = [
    { question: `What is DSLIB?`, answer: (
        <p className="answer-text">
        DSLIB is a platform that enables you to do product research to find your next product to sell.<br /><br />
        With DSLib, you can:<br />
        • Perform product research through millions of products<br />
        • Track everyday sales of stores and products and measure their performance<br />
        • Search and find your competitors in the market<br />
        • Receive up to 40 winning products every week<br />
        <br />
        DSLIB has a variety of tools built by sellers for sellers with the mission to help entrepreneurs start and grow successful businesses.<br /><br />
      </p>

    )},

    // { question: `Can I cancel anytime?`, answer: `Yes, you can cancel your subscription at any time. In the event that you cancel, your subscription will end at the end of the current term without further charges.`},
    { question: `What payment methods do you accept?`, answer: (
    <p className="answer-text">
    DSLIB accepts the following payment methods through secure onsite payment processing:<br /><br />
    • Visa<br />
    • Mastercard<br />
    • American Express<br />
    • Discover<br />
    • Diner's Club<br />
    • JCB<br />
    • China UnionPay<br />
    <br />
    PayPal payments are also accepted.
  </p>
)},

    { question: `What is dropshipping?`, answer: `Dropshipping is a business model that allows you to sell products without having to buy and stock up inventory beforehand. Basically, when you get a sale on your store, you provide a supplier with the order details and pay for the product and shipping cost to have the supplier ship the order out for your customers. This way, you won't need to buy stock in advance and that brings the barrier down for you to test many products and find winners.`},
    { question: `How do I find products?`, answer: (
        <p className="answer-text">
          DSLIB offers tools to help you with the product research phase.<br /><br />
          • Product Database<br />
          • Sales Tracker<br />
          • Competitor Research<br />
          • Portfolio<br /><br />
          We have guides and short tutorials you can <a href="/university" target="_blank" className="link _18-px _16-px">watch here</a> to better understand how each tool works.<br />
        </p>
      ),},
    { question: 'What is Product Database?', answer: 'Product Database is a database that consists of millions of products. You can view what products a store is selling and how much revenue they are making in real-time with Product Database. It is the best way to validate if a product is in demand or not.' },
    { question: 'In what countries can I use DSLIB?', answer: 'You can use DSLIB in every country in the world.' },

    // Add more FAQ items as needed
  ];

  return (
    <div className="container mt-5 mb-5">
      <h2 className="mb-4 text-center heading2">Frequently Asked Questions </h2>
      <p className='text-center description'>Everything you need to know about DSLIB.</p>
      {faqData.map((item, index) => (
        <div key={index} className="accordion description">
          <div className="accordion-item">
            <h2 className="accordion-header" id={`question-${index}`}>
              <button className="accordion-button"  style={{backgroundColor:"#fff"}} type="button" data-bs-toggle="collapse" data-bs-target={`#answer-${index}`} aria-expanded="true" aria-controls={`answer-${index}`}>
                {item.question}
              </button>
            </h2>
            <div id={`answer-${index}`} className="accordion-collapse collapse" aria-labelledby={`question-${index}`} data-bs-parent="#accordionExample">
              <div className="accordion-body">
                {item.answer}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
