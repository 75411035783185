// import { CLIENT_ID } from '../Config/Config'
import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const CheckoutForm = () => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
  const { state } = useLocation()
  const navigate = useNavigate()
 // console.log(state.inputValue)
  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: "Dropshipping Tool",
          amount: {
            currency_code: "USD",
            value: 15,
          },
          logoUrl: "path/to/dropshipping-tool-logo.png",

        },
      ],
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };
  const saveFormData = async () => {
    const updatedInputValue={
      ...state.inputValue,orderID
    }
   // console.log('updated value',updatedInputValue)
    try {
      // Check if state is not null or undefined before accessing 'inputValue'
      if (state && state.inputValue) {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/register`, updatedInputValue);
        // Handle the response if needed
       // console.log(response.data);
      } else {
        console.error("State or state.inputValue is null or undefined");
      }
    } catch (error) {
      // Handle errors from the server
      console.error("Error saving form data:", error);
    }
  };

  useEffect(() => {
    if (success) {
      saveFormData()
      navigate('/success', { state: { orderID } })
    }
  }, [success]);

  return (
    <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
      <div style={{ backgroundColor: "#fff", height: "100vh" }} className="container mt-5">
        <div className="row">
          <div className="col-lg-2 col-xl-2 col-xxl-2"></div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div style={{ border: "1px solid #ed106f" }} className='rounded'>
              <div className="card text-center">
                <div className="card-header" style={{ backgroundColor: "#ed106f", color: "#fff" }}>
                  <h5 className="card-title">Premium Plan</h5>
                </div>
                <div className="card-body">
                  <h1 className="card-title heading2 text-center">$15/mo</h1>
                  <h3 className="previous-price  text-center">$25/mo</h3>
                </div>
                <ul className="list-group list-group-flush description">
                  <li className="list-group-item">Unlimited Searches Product Database</li>
                  <li className="list-group-item">Unlimited Searches Competitor Research</li>
                  <li className="list-group-item">Top Products Full Access</li>
                  <li className="list-group-item">Portfolio 50 Products / Week</li>
                  <li className="list-group-item">And Many More</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mt-3 mt-xl-0 mt-lg-0">
            <PayPalButtons
              style={{ layout: "vertical" }}
              createOrder={createOrder}
              onApprove={onApprove}              
            />
          </div>
          <div className="col-lg-3 col-xl-3 col-xxl-3"></div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default CheckoutForm