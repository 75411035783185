import React from 'react'
import './product.scss'
import { BsArrowRightShort } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const Product = () => {
    return (
        <div className='row'>
            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                <h2 className='heading2 text-start'>Millions Of Products At Your Fingertips</h2>
                <div className='description  text-start' style={{ paddingRight: "0rem", marginBottom: "3rem" }}>Find your next profitable product by exploring our vast database with millions of products. Use our smart filters to refine your search and find products tailored to your interests and niche.
                </div>
                <div className='button_area'>
                    <Link className='product_button' type="" to="/register" >GET STARTED <BsArrowRightShort className='arrow_sign' /></Link>

                </div>
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-end' style={{ marginBottom: "5rem" }}>
                <img src="./images/slider/EarPhone.png" alt="doctor" className='mt-3 right_image rounded-top'/>
            </div>
        </div>
    )
}

export default Product