import React from 'react'
import {AiOutlineCheckCircle, AiOutlineCloseCircle} from 'react-icons/ai'
import './transform.scss'
const data1 = [
    ' Wasting time on research',
    ' Guessing what works',
    ' Late to market',
    ' Searching for strategy'
]

const data2 = [
    ' Instant access to insights',
    ' Knowing what works',
    ' Early to market',
    ' Profitable growth'
]
export const Transform = () => {
    return (
        <>
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <h2 className='heading2 text-left text-xl-center mb-5'>Transform your business with DSLib</h2>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row flex-xl-row justify-content-around'>
                <div className=''>
                    <h3 className='heading2 text-left mb-5' style={{ fontSize: "2rem" }}>Before DSLib</h3>
                    {
                        data1.map((e, index) => {
                            return (
                                <div key={index} className='mb-3 description'><AiOutlineCloseCircle className='close_sign' />{e}</div>

                            )}  
                        )
                    }
                </div>
                <div className=''>
                    <h3 className='heading2 text-left mb-5' style={{ fontSize: "2rem" }}>With DSLib </h3>
                    {
                        data2.map((e, index) => {
                            return (
                                <div className='mb-3 description' key={index}><AiOutlineCheckCircle className='check_sign' />{e}</div>

                            )}
                        )
                    }
                </div>

            </div>
        </>
    )
}
