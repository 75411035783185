import React, { useEffect, useState } from "react";
import axios from "axios";

export const ProductList = () => {
  const [pageNum, setPageNum] = useState(1)
  const [list, setList] = useState([])
  useEffect(() => {
    // Define the request headers
    const headers = {
      "CJ-Access-Token":
        `${process.env.REACT_APP_CJ_ACCESS_TOKEN}`
    };

    const params = {
      pageSize: 100, // Number of products to retrieve
      pageNum,
      total: 10000

    };

    // Make the API request
    axios
      .get(
        `${process.env.REACT_APP_CJ_DROPSHIP_URL}/product/list`,
        {
          params,
          headers: headers
        }
      )
      .then((response) => {
        // Handle the response here
       // console.log("Response:", response.data);
        setList(response.data.data.list)
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error:", error);
      });
  }, [pageNum]);

  if (list.length < 1) {
    return <div>Loading...</div>
  }


  let filterList = list.filter((e) => e.listingCount > 1)
 // console.log(filterList)
  return (
    <div>
      <h2 className="text-center">Top Winning Products</h2>
      <div className="d-flex flex-row-reverse mb-3">
        <button onClick={() => setPageNum(pageNum + 1)} className="btn btn-success" style={{border:"1px solid #ed106f"}}>Next</button>
        <button onClick={() => setPageNum(pageNum - 1)} className="btn btn-success" disabled={pageNum===1} style={{border:"1px solid #ed106f"}}>back</button>
      </div>
      {/* <p>page Number: {pageNum}</p> */}
      <div className="row z-index-2 d-flex justify-content-between">
        {
          filterList.map((e) => {
            return (
              <div key={e.pid} className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3  shadow-md shadow-lg mb-3">
                <img src={e?.productImage} height="250" width="278" className="rounded-top" />
                <div>{e?.productNameEn}</div>
                <div style={{fontWeight:"500"}}>${e?.sellPrice}</div>
              </div>
            )
          })

          // <div>{list[0].pid}</div>
        }
      </div>

    </div>
  );
}


