// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

import axios from "axios";
import { useEffect, useState } from "react";

// const ProductList2 = () => {
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     const headers = {
//       "CJ-Access-Token":
//         `${process.env.REACT_APP_CJ_ACCESS_TOKEN}`
//     };
  
//     const fetchData = async () => {
//       const pageSize = 200; // Maximum page size supported by the API
//       const targetItemCount = 500; // Number of items you want to retrieve
//       const pageCount = Math.ceil(targetItemCount / pageSize);
//       let allData = [];

//       for (let page = 1; page <= pageCount; page++) {
//         try {
//           const response = await axios.get(
//             "https://developers.cjdropshipping.com/api2.0/v1/product/list",
//             {
//               headers: headers,
//               params: {
//                 page: page,
//                 pageSize: pageSize
//               }
//             }
//           );

//           const pageData = response.data.data.list;

//           // Add the data to the allData array
//           allData = [...allData, ...pageData];
//           //console.log(allData)

          
//         } catch (error) {
//           console.error("Error fetching data for page", page, ":", error);
//         }
//       }

//       // Set the products state with the mapped data
//       //setProducts(allData.map(mapProductData));
//     setProducts(allData.filter(product => product.listingCount > 1).map(mapProductData));

//     };

//     fetchData();
//   }, []); // The empty dependency array ensures that this effect runs once, equivalent to componentDidMount


//   const mapProductData = (product) => {
//     return {
//       productId: product.productId,
//       productName: product.productNameEn,
//       // Add more properties as needed
//     };
//   };
  
// console.log("products",products)
// // const filterProducts=products.filter((e)=>e.listedNum>2)
// //console.log("filter",filterProducts)
//   return (
//     <div>
//       <h1>Product List</h1>
//       <ul>
//         {products.map((product) => (
//           <li key={product.pid}>
//             Product Name: {product.productName}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default ProductList2;

export const ProductList2 = () => {
  const [pageNum, setPageNum] = useState(1);
  const [list, setList] = useState([]);

  // Add state for category
  const [selectedCategory, setSelectedCategory] = useState("all"); // Default to "all" categories

  useEffect(() => {
    const headers = {
      "CJ-Access-Token": `${process.env.REACT_APP_CJ_ACCESS_TOKEN}`
    };

    const params = {
      pageSize: 100,
      pageNum,
      total: 10000
    };

    axios
      .get(`${process.env.REACT_APP_CJ_DROPSHIP_URL}/product/list`, {
        params,
        headers: headers
      })
      .then((response) => {
        setList(response.data.data.list);
        console.log(response.data.data.list)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [pageNum]);

  if (list.length < 1) {
    return <div>Loading...</div>;
  }

  let filteredList;

  // Filter based on category
  if (selectedCategory === "all") {
    // If "all" categories selected, show all products
    filteredList = list;
  } else {
    // Filter based on the selected category
    filteredList = list.filter((e) => e.category === selectedCategory);
  }

  return (
    <div>
      <h2 className="text-center">Products</h2>

      {/* Add a dropdown for selecting categories */}
      <select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="all">All Categories</option>
        <option value="beauty">Beauty</option>
        <option value="electronics">Electronics</option>
        <option value={"Home Office Storage"}>Home Office Storage</option>
        {/* Add more options based on your actual categories */}
      </select>

      {/* Rest of your code... */}
      
      <div className="row z-index-2 d-flex justify-content-between">
        {filteredList.map((e) => (
          <div key={e.pid} className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3  shadow-md shadow-lg mb-3">
            <img src={e?.productImage} height="250" width="278" className="rounded-top" alt={e?.productNameEn} />
            <div>{e?.productNameEn}</div>
            <div style={{ fontWeight: "500" }}>${e?.sellPrice}</div>
            <div>{e?.productType}</div>
          </div>
        ))}
      </div>
    </div>
  );
};








