import React from 'react'
import { Link } from 'react-router-dom'

export const Fail = () => {
    return (
        <div className='container'>
            <div className="alert alert-danger" role="alert" style={{color:"red"}}>
                Your payment was Fail !
            </div>
            Again try to <Link to="/register" style={{color:"#ed106f"}}> Register</Link>

        </div>
    )
}
