import React from 'react'
import './product.scss'
import { BsArrowRightShort } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const Product2 = () => {
    return (
        <div className='row'>            
            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-end justify-content-xl-start' style={{ marginBottom: "5rem" }}>
                <img src="./images/slider/TableLamp.png" alt="doctor" className='mt-3 right_image rounded-top'/>
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                <h2 className='heading2'>Receive The Best Products Weekly</h2>
                <div className='description' style={{  marginBottom: "3rem" }}>On Monday at 10 PM EST every week, a drop containing up to 50 winning products is released to your Portfolio.
                </div>
                <div className='button_area'>
                    <Link className='product_button2' type="submit" to="/register" >GET STARTED <BsArrowRightShort className='arrow_sign' /></Link>

                </div>
            </div>
        </div>
    )
}

export default Product2