import "./register.scss"
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

export const RegisterForm = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
    username: "",
  });
  const [error, setError] = useState(null);
  const { username, email, password } = inputValue;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });

    checkEmailAvailability(value);
    setInputValue((updatedInputValue) => {
      return updatedInputValue;
    });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   // console.log(inputValue)
    if (!inputValue.password || inputValue.password.length < 8) {
      setError('Password should be at least 8 characters long')
    } else {
     // console.log(error)
      navigate('/checkout', { state: { inputValue } })
    }
  };

  const checkEmailAvailability = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/check-email?email=${email}`);
      const data = await response.json();
      // console.log(data.isEmailTaken)
      if (data.isEmailTaken) {
        setError('Email is already in use!')
      } else if(!data.isEmailTaken){
        console.log('Email is available!');
      }
    } catch (error) {
      console.error('Error checking email availability:', error);
    }
  };
  return (
    <div style={{  height: "100vh" }} className="d-flex justify-content-center align-items-center register_container">
      <form onSubmit={handleSubmit} className="container rounded" style={{ backgroundColor: "#fff", height: "580px", width: "400px" }}>
        <h5 className="text-center mb-3 pt-3" style={{ fontWeight: "700" }}>REGISTER</h5>
        <div className="mb-3 row">
          <div className="col-12">
            <label htmlFor="staticEmail1" className=" col-form-label label_text">*Email ID</label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-12">
            <input type="text" className="form-control" id="staticEmail2" name="email" value={email} onChange={handleOnChange} required placeholder="Enter Email" />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-12">
            <label htmlFor="staticEmail" className=" col-form-label label_text">*Full Name</label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-12">
            <input type="text" className="form-control" id="staticEmail" name="username" value={username} onChange={handleOnChange} required placeholder="Enter Full Name" />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-12">
            <label htmlFor="inputPassword" className="col-form-label label_text">*Password</label>
          </div>
        </div>
        <div>
          <div className="col-12">
            <input type="password" className="form-control" id="inputPassword" name="password" value={password} onChange={handleOnChange} required placeholder="Enter Password" />
          </div>
        </div>
        <div className="mt-3 row">
          <div className="col-12">
            <div className="form-check" style={{ color: "#bd7a37" }}>
              <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required />
              <label className="form-check-label" htmlFor="invalidCheck">
                Agree to <Link to="/privacy-policy" style={{ color: "#bd7a37" }}>Privacy</Link> and <Link to="/terms-condition" style={{ color: "#bd7a37" }}>terms conditions</Link>
              </label>
              <div className="invalid-feedback">
                You must Agree to terms and conditions.
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-12">
            <div className='login_button_area'>
              <button className='register_button' type="submit">NEXT PAYMENT</button>
            </div>
          </div>
          <span className="mt-3">
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </span>
        </div>
        <span>
          Already have an account? <Link to={"/login"}>Login</Link>
        </span>
      </form>
    </div>

  )
}