import "./reviews.scss"
export const Reviews = () => {
    return (
        <>
        <h2 className="text-center mb-5 heading2" style={{fontWeight:"700"}}>What Our Customers Say</h2>
        <div className="slider-container mb-5">
            <div className="slider">
                <img src="./images/reviews/review1.png" alt="Image 1"/>
                <img src="./images/reviews/review2.png" alt="Image 2" />
                <img src="./images/reviews/review3.png" alt="Image 3" />
                <img src="./images/reviews/review4.png" alt="Image 4" />
                <img src="./images/reviews/review5.png" alt="Image 5" />
                <img src="./images/reviews/review6.png" alt="Image 6" />
                <img src="./images/reviews/review7.png" alt="Image 7" />
                <img src="./images/reviews/review8.png" alt="Image 8" />
            </div>
        </div>
</>

    )
}