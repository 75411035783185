import React, { useState, useEffect } from 'react';
import './slider.scss'
const images = [
  './images/slider/Cycling Shoes.png',
  './images/slider/Digital Clock.png',
  './images/slider/Dog Towels.png',
  './images/slider/Humidifier Diffuser.png',
  './images/slider/Shower Nozzle.png',
  './images/slider/Head Phone.png'
 
];

function Slider() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="image-slider">
      <img src={images[currentImageIndex]} alt={`Slider ${currentImageIndex + 1}`} className='rounded-top'/>
    </div>
  );
}

export default Slider;
