import React from 'react'
import { ProductList } from './ProductList/ProductList'
import { useNavigate } from 'react-router-dom'

export const Dashboard = () => {
  const navigate = useNavigate()
  function logOut() {
    localStorage.clear('token')
    navigate('/login')
  }
  return (
    <div className='container'  style={{height:"auto"}}>
      <button onClick={logOut} className='btn btn-success' style={{border:"1px solid #ed106f"}}>Log Out</button>
      <ProductList />
    </div>
  )
}
