import React from 'react'
import Hero from './Hero/Hero'
import { Help } from './Help/Help'
import { Seller } from './Seller/Seller'
import Product from './Product/Product'
import { Transform } from './Transform/Transform'
import { Reviews } from './Reviews/Reviews'
import FAQ from './FAQ/FAQ'
import { Plan } from './Plan/Plan'
import Product2 from './Product/Product2'
export const Home = () => {
  return (
    <div>
        <Hero/>
        <Help/>
        <Product/>
        <Seller/>
        <Product2/>
        <Transform/>
        <Plan/>
        <FAQ/>
        <Reviews/>
    </div>
  )
}


//https://www.istockphoto.com/search/search-by-asset?searchbyasset=true&assettype=image&page=3&assetid=1430628751