// PricingCard.js
import "./pricingCard.scss"
import React from 'react';
import { Link } from 'react-router-dom';

export const PricingCard = () => {
    return (
        <div  style={{border:"1px solid #ed106f"}} className='rounded'>
        <div className="card text-center">
            <div className="card-header" style={{backgroundColor:"#ed106f",color:"#fff"}}>
                <h5 className="card-title">Premium Plan</h5>
            </div>
            <div className="card-body">
                <h1 className="card-title heading2 text-center">$15/mo</h1>
                <h3 className="previous-price  text-center">$25/mo</h3>
            </div>
            <ul className="list-group list-group-flush description">
                <li className="list-group-item">Unlimited Searches Product Database</li>
                <li className="list-group-item">Unlimited Searches Competitor Research</li>
                <li className="list-group-item">Top Products Full Access</li>
                <li className="list-group-item">Portfolio 50 Products / Week</li>   
                <li className="list-group-item">And Many More</li>                
             
            </ul>
            <div className="card-body">
                {/* <button className="btn btn-primary">Subscribe</button> */}
            <div className='login_button_area'>
                {/* <a  className='register_button'>NEXT PAYMENT</a> */}
                <Link className='pricing_button' type="submit" to="/register" >GET STARTED</Link>
            </div>
            </div>

        </div>
        </div>
    );
};
