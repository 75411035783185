import React from 'react'
// import './help.scss'
const data = [
    {
        'id': 1,
        "url":"./images/shopify.svg"

    },
    {
        'id': 2,
        "url":"./images/amazon.svg"

    },
    {
        'id': 3,
        "url":"./images/ebay.svg"

    },

    {
        'id':4,
        "url":"./images/wix.svg"
    },
    {
        'id':5,
        "url":"./images/newabc.png"
    },
    {
        'id':6,
        "url":"./images/Bigcommerce.svg"
    },

]
export const Seller = () => {
    return (
        <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <h2 className='help_heading'>APPLIES TO ALL SELLERS</h2>
            </div>
            <div className='row  d-flex justify-content-center' style={{ marginBottom: "5rem", marginTop: "2rem" }}>
                {
                data.map((e) => (
                    <div className='col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-3 m-lg-0 m-xl-0 m-xxl-0' key={e.id}>
                        <img src={e.url} alt='seller'/>
                    </div>
                ))

                }

            </div>

        </div>
    )
}
