import React from 'react'
import './footer.scss'
import { BsTelephone } from 'react-icons/bs'
import { GrMail } from 'react-icons/gr'
import { SlLocationPin } from 'react-icons/sl'
import SocialMedia from './SocialMedia'
import { Link } from 'react-router-dom'
export const Footer = () => {
    return (
        <div style={{ marginTop: "5rem" }}>
            <footer className="bg-light py-3" style={{ borderTop: "1px solid #d3d3d3" }}>
                <div>
                    <div className="row ">
                        {/* <div className="col-6 col-lg-2">
                            <h5 className='title'>Company Info</h5>
                            <ul className="list-unstyled">
                                <li><a href=" #" className='text-muted'>About Us</a></li>
                                <li><a href=" #" className='text-muted'>Carrier</a></li>
                                <li><a href=" #" className='text-muted'>We are hiring</a></li>
                                <li><a href=" #" className='text-muted'>Blog</a></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h5 className='title'>Legal</h5>
                            <ul className="list-unstyled">
                                <li><a href=" #" className='text-muted'>About Us</a></li>
                                <li><a href=" #" className='text-muted'>Carrier</a></li>
                                <li><a href=" #" className='text-muted'>We are hiring</a></li>
                                <li><a href=" #" className='text-muted'>Blog</a></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h5 className='title'>Features</h5>
                            <ul className="list-unstyled">
                                <li><a href=" #" className='text-muted'>Business Marketing</a></li>
                                <li><a href=" #" className='text-muted'>User Analytic</a></li>
                                <li><a href=" #" className='text-muted'>Live Chat</a></li>
                                <li><a href=" #" className='text-muted'>Unlimited Support</a></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h5 className='title'>Resources</h5>
                            <ul className="list-unstyled">
                                <li><a href=" #" className='text-muted'>IOS & Android</a></li>
                                <li><a href=" #" className='text-muted'>Watch a Demo</a></li>
                                <li><a href=" #" className='text-muted'>Customers</a></li>
                                <li><a href=" #" className='text-muted'>API</a></li>
                            </ul>
                        </div> */}
                        <div className="col-12">
                            {/* <h5 className='title'>Get In Touch</h5> */}
                            <ul className="list-unstyled">
                                {/* <li><a href=" #" className='text-muted'><BsTelephone style={{ color: "#ed106f", marginRight: "1rem" }} /></a>(480) 555-0103</li>
                                <li><a href=" #" className='text-muted'><SlLocationPin style={{ color: "#ed106f", marginRight: "1rem" }} /></a>4517 Washington Ave. Manchester,
                                    Kentucky 39495</li> */}
                                <li style={{ color: "#090e35", fontSize: "13px" }}><a href=" #" ><GrMail style={{ color: "#ed106f", marginRight: "1rem" }} /></a>support@dslib.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mt-3 d-flex justify-content-between">
                        <div className="col-12 col-lg-6 col-xl-6 text-left" style={{ color: "#090e35", fontSize: "13px" }}>
                            Copyright @ 2023 DSLib. All Right Reserved
                        </div>
                        <div className='col-6 col-lg-3 col-xl-3'>
                            <Link to="/privacy-policy" className='m-1 ' style={{ color: "#bd7a37", fontSize: "13px" }}>Privacy</Link>
                            <Link to="/terms-condition" className='m-1 ' style={{ color: "#bd7a37", fontSize: "13px" }}>Terms</Link>
                        </div>

                        <div className='col-6 col-lg-3 col=xl=3'>
                            <SocialMedia />
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}