import React from 'react'
import "./navbar.scss"
import { BsArrowRightShort } from "react-icons/bs";
import { isAuthenticated } from '../../routes/auth';

export const Navbar = () => {
    const token = isAuthenticated()
    return (
        <div className='container'>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand logo" href="/">DSLib</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse abcd" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                                {/* <a className="nav-link" aria-current="page" href="/">Home</a> */}
                            </li>
                            <li className="nav-item">
                                {/* <a className="nav-link" href=" #">Product</a> */}
                            </li>
                            <li className="nav-item">
                                {/* <a className="nav-link" href=" #">Pricing</a> */}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/contact">Contact</a>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto pr-10">
                            {
                                token ? (
                                    <li className="nav-item">
                                        <a className="nav-link h6" href="/dashboard" style={{ color: 'var(--bs-text-color)', marginRight: "1rem" }}>Dashboard</a>
                                    </li>

                                )
                                    :
                                    (
                                        <>
                                            <li className="nav-item">
                                                <a className="nav-link h6" href="/login" style={{ color: 'var(--bs-text-color)', marginRight: "1rem" }}>Login</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav_button" href="/register" style={{ color: "#ffffff" }}>
                                                    Get Started
                                                    <BsArrowRightShort className='arrow_sign' />
                                                </a>
                                            </li>
                                        </>
                                    )
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>

    )
}
