import React from 'react';
import { PricingCard } from './PricingCard';

export const Plan = () => {
  return (
    <div className="container mb-5 mt-5">
        <h2 className='text-center mb-5 heading2'>Plans And Pricing</h2>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <PricingCard/>
        </div>
      </div>
    </div>
  );
};


