import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export const Success = () => {
    const { state } = useLocation()

    return (
        <div className='container'>
            <div className="alert alert-success" role="alert" style={{color:"green"}}>
                your payment was successfull !
            </div>
            {state && state.orderID && (
                <h3>Your order ID is {state.orderID}</h3>
            )}

            <div className='login_button_area'>
              <Link to='/login' className='register_button' type="submit">LOGIN</Link>
            </div>
          


        </div>
    )
}
