// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { Navbar } from './components/Navbar/Navbar';
import { Home } from './pages/Home/Home';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { RegisterForm } from './pages/Register/RegisterForm';
import { LoginForm } from './pages/Login/LoginForm';
import { Privacy } from './pages/Privacy/Privacy';
import { ContactPage } from './pages/Contact/Contact';
import ProtectedRoute from './routes/ProtectedRoute';
import { Terms } from './pages/Terms/Terms';
import { Support } from './pages/Support/Support';
import { Cancellation } from './pages/Cancellation/Cancellation';
import CheckoutForm from './pages/Payment/CheckoutForm';
import { Success } from './components/Messages/Success';
import { Fail } from './components/Messages/Fail';
import { Footer } from './pages/Home/Footer/Footer';
import { ProductList2 } from './pages/Dashboard/ProductList/ProductList2';
// import { CategoryList } from './pages/Dashboard/Category/CategoryList';
//import ProductList2 from './pages/Dashboard/ProductList/ProductList2';

function App() {
  return (
    <div className='main container'>
      <BrowserRouter>
      <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path="/register" element={<RegisterForm />}></Route>
          <Route exact path="/login" element={<LoginForm />}></Route>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/checkout" element={<CheckoutForm/>}></Route>
          <Route exact path="/success" element={<Success/>}></Route>
          <Route exact path="/fail" element={<Fail/>}></Route>
          <Route exact path="/contact" element={<ContactPage />}></Route>
          <Route exact path="/privacy-policy" element={<Privacy />}></Route>
          <Route exact path="/terms-condition" element={<Terms/>}></Route>
          <Route exact path='"/support' element={<Support/>}></Route>
          <Route exact path='"/cancellation' element={<Cancellation/>}></Route>
          <Route exact path='/category' element={<ProductList2/>}></Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
