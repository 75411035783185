import axios from "axios";
// import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const ContactPage = () => {
    // const navigate = useNavigate();
    const [isRobot, setIsRobot] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: '',
        subject: ''
    });
console.log(process.env.REACT_APP_RECAPTCHA_SITEKEY)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [e.target.name]: '',
        });

    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate Name
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim() || !emailRegex.test(formData.email)) {
            newErrors.email = 'Valid email is required';
            isValid = false;
        }

        // Validate Message
        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
            isValid = false;
        }
        if (!formData.subject.trim()) {
            newErrors.subject= 'Subject is required';
            isValid = false;
        }

        setErrors(newErrors);

        return isValid;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isRobot) {
            // console.log("Form Data:", formData);
            if (validateForm()) {
                try {
                    const { data } = await axios.post('https://dropshippingtool-server.vercel.app/contact', formData)
                    const { success, message } = data;
                    if (success) {
                        // handleSuccess(message);
                        setFormData({
                            email: "",
                            subject: "",
                            name: "",
                            message: ""
                        });
                        setError("")
                        setIsRobot(false)
                        alert(message)
                        // setTimeout(() => {
                        //     navigate("/");
                        // }, 1000);
                    } else {
                        setError(message);
                    }
                } catch (error) {
                    if (error.response) {
                        setError(error.response.data.error || 'An error occurred');
                    } else if (error.request) {
                        setError('No response received from the server');
                    } else {
                        setError('An error occurred while processing the request');
                    }
                }
            }
        } else {
            alert("Please verify that you are not a robot.");
        }
    };

    return (
        <div className="container mt-5 d-flex justify-content-center align-items-center register_container" style={{height: "100vh" }}  >
            <form onSubmit={handleSubmit} className="container rounded" style={{ backgroundColor: "#fff", height: "640px", width: "400px" }}>
                <h2 className="text-center mt-3">Contact Us</h2>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label label_text">
                        *Name:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        placeholder="your name"
                    />
                </div>
                <div className="error" style={{ color: 'red' }}>{errors.name}</div>

                <div className="mb-3">
                    <label htmlFor="email" className="form-label label_text">
                        *Email:
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        placeholder="your email"
                    />
                </div>
                <div className="error" style={{ color: 'red' }}>{errors.email}</div>

                <div className="mb-3">
                    <label htmlFor="subject" className="form-label label_text">
                        *Subject:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                        placeholder="your subject"
                    />
                </div>
                <div className="error" style={{ color: 'red' }}>{errors.subject}</div>

                <div className="mb-3">
                    <label htmlFor="message" className="form-label label_text">
                        *Message:
                    </label>
                    <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        placeholder="your message"
                    ></textarea>
                </div>
                <div className="error" style={{ color: 'red' }}>{errors.message}</div>
                <div>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                        onChange={(value) => setIsRobot(!!value)}
                    />
                </div>
                <div className='login_button_area'>
                    <button className='login_button' type="submit">SEND</button>
                </div>
                <span className="mt-3">
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </span>
            </form>

        </div>
    );
};

